.btn {
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-weight: 600;
  border-radius: 5px;
  padding: .6rem 2rem;
  border-radius: .4rem;
  color: var(--white);
  background: #207fe6;
  line-height: 1.15;
  font-size: 15px;
  word-spacing: 0px;
  letter-spacing: .06em;
  text-decoration: none;
  border: 1px solid transparent;
  text-align: center;
  box-shadow: var(--box-shadow);
  transition: all 280ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 11rem;
  margin-bottom: .8rem;

  &:hover { 
    background: #095eba;
    border: 1px solid #0f6fd5;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.122), 0px 4px 5px 0px rgba(0, 0, 0, 0.088), 0px 1px 10px 0px rgba(0, 0, 0, 0.095);  }
}            
