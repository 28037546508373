.rounded-btn {
  display: flex;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  margin-right: .8rem;
  margin-top: 1rem;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  background: var(--blue);
  box-shadow: var(--box-shadow);

  &:hover {
    background: #06509f;
    box-shadow: -3px -3px 6px rgba(255, 255, 255, 1), 4px 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
}

