.todo-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;

  &__inputs {
    display: flex;
    flex-direction: column;
    width: 30%;
    min-width: 10rem;
  }

  &__todo-items {
    flex-grow: 2;
  }

  &__btn-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: .7rem;
  }
}

.add-list__btn {
  margin-top: 1rem;
}

.todo-title,
.todo-description {
  margin-bottom: .8rem;
}

.todo-title,
.todo-description,
.todo {
  background: var(--light-grey);
  box-shadow: var(--inner-shadow);  
  border: none;
  padding: .6rem;
  letter-spacing: .3px;
  border-radius: 4px;
  outline: none;
  color: var(--dark-grey);
}

.todo-title::placeholder,
.todo-description::placeholder,
.todo::placeholder {
  color: var(--medium-grey);
}