.modify-form {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__label {
    margin-top: 1rem;
  }
  &__label--name {
    margin-top: .4rem;
  }

  &__label,
  &__label--name {
    margin-top: 1rem;
    margin-bottom: .3rem;
    font-size: 13px;
    color: var(--dark-grey);
  }
}

.subtodo-item-input {
  height: 1.8rem;
}

.subtodo-notes-input {
  padding-top: .3rem;
}

.subtodo-notes-input,
.subtodo-item-input {
  margin-top: 1.6 rem;
  padding-left: .3rem ;
  background: rgb(250, 250, 250);
  box-shadow: var(--inner-shadow);
  outline: none;
  border: none;
  border-radius: 4px;
}

.subtodo-notes-input::placeholder,
.subtodo-item-input::placeholder {
  color: var(--medium-grey);
}

.edit-subtodo__btn {
  margin-top: 1.1rem;
}