@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,400;1,700&display=swap');

:root {
  --blue: #0964C8;
  --light-grey: #e3ebf1;
  --medium-grey: #a8a9b5;
  --dark-grey: #525461;
  --white: #ffffff;
  --box-shadow: -2px -2px 5px rgba(255, 255, 255, 1), 3px 3px 5px rgba(0, 0, 0, 0.1);
  --inner-shadow: inset -2px -2px 5px rgba(255, 255, 255, 1), inset 2px 2px 5px rgba(0, 0, 0, 0.2);;
}

html {
  box-sizing: border-box;
  min-height: 100%;
  position: relative;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-family: 'Hind Madurai', sans-serif;
}

body {
  margin: 0;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--light-grey);
}

.App {
  text-align: center;
  color: var(--dark-grey);
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3.5rem;
  margin-bottom: 4rem;
}

.colors-border {
  background-color: none;
  width: 22rem;
  height: 14rem;
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  border-radius: 10px;
  padding: 25px;
}

.colors-border:before,
.colors-border:after {
  content: '';
  position: absolute;
  background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00, #ff0000, #fb0094, #0000ff, #ffff00, #ff0000);  
  background-size: 400% 400%;
  width: 18.7rem;
  height: 13rem;
  border-radius: 20px;
  animation: animate 5s ease alternate infinite;
  opacity: 60%;
}

.colors-border:after {
  filter: blur(20px);
}

@keyframes animate {
  0%{
    background-position: 0% 50%;
  }
  50%{
    background-position: 100% 50%;
  }
  0%{
    background-position: 0% 50%;
  }
}

.main__text-wrapper {
  box-shadow: var(--inner-shadow);
  border-radius: 10px;
  width: 20rem;
  height: 13rem;
  margin: auto;
  padding: 2rem 2rem;
  text-align: justify;
  z-index: 1;
  background-color: var(--light-grey);
}

.main-img {
  width: 9rem;
}

.title {
  font-size: 23px;
  margin-bottom: 2rem;
}

.typewriter {
  width: 23.7rem;
  margin: auto;
}

.typewriter h1 {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .11em solid rgb(100, 98, 97); /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .03em; /* Adjust as needed */
  animation: 
    typing 2s steps(40, end),
    blink-caret .75s step-end infinite;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: rgb(100, 99, 97); }
}


.main__app-description,
.main__app-signature {
  font-size: 14px;
  line-height: 18px;
}

.main__app-signature {
  margin-top: 1.3rem;
  text-align: right;
}

.main {
  width: 100%;
}

.card-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}