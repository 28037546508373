.card {
  margin: 1rem;
  height: 14rem;  
  width: 16rem;
  background: #ebf5fc;
  box-shadow: -2px -2px 5px rgba(255, 255, 255, 1), 3px 3px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 1rem;
  overflow-wrap: break-word;
  justify-content: space-between;

  &:hover {
    background-color: rgb(242, 248, 255);
  }

  &__btn-wrapper {
    display: flex;
    justify-content: right;
    bottom: .5rem;
    position: relative;
  }
}

.card-text {
  font-size: 14px;
  margin-top: 1rem;
  max-height: 3rem;
  overflow: hidden;
}

.card-title {
  border-bottom: solid 1px rgb(180, 184, 213);
  padding-bottom: .5rem;
}
