
.labels {
  font-size: 10px;
  color: var(--blue);
  margin-right: .3rem;
  white-space: nowrap;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 8px;
  background-color: lightgray;
}