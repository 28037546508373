.footer-wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.095);

  &__link {
    margin: 0 2rem;
    margin-left: 2%;
    margin-right: 2%;
    font-size: 12px;
    color: var(--dark-grey);
  }
}

.footer-wrapper__link a {
  color: var(--blue);
}